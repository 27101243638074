<template>
  <div class="page1">
    <EForm
      :formColumns="formColumns"
      :rowSize="4"
      :optionsBtn="true"
      :actionBtn="false"
      :formData="searchForm"
      ref="form"
      :searchFlag="true"
      url="feeCollectExport"
      fileName="所属期费用汇总"
      :exportData="exportDataFn()"
      @onSearch="getData"
      @getChange="changeSelect"
    ></EForm>
    <!--    <div class="mt20 fs15">-->
    <!--      统计时间 ：<span></span>{{searchForm.payTimeStart||'-'}}  至  {{searchForm.payTimeEnd||'-'}}-->
    <!--    </div>-->
    <ETable
      :tableTitle="tableTitle"
      :tableData="tableData"
      :needPagination="false"
      :showSummary="true"
      :getSummaries="getSummaries"
      :needIndex="false"
    ></ETable>
  </div>
</template>

<script>
  import ETable from '@/components/ETable';
  import Http from '@/service/http';
  import EDialog from '@/components/EDialog';
  import EButton from '@/components/EButton';
  import EForm from '@/components/EForm';
  import { identity_type, status } from '@/assets/js/config';
  import vxRule from '@/assets/js/formValidate';

  export default {
    name: 'costSummary',
    data() {
      return {
        tableTitle: [
          {
            label: '费用名称',
            prop: 'takeReturnProjectName',
          },
          {
            label: '应收',
            prop: 'receivableAmount',
          },
          {
            label: '已收',
            prop: 'receivedAmount',
          },
          {
            label: '未收',
            prop: 'unReceivedAmount',
          },
        ],
        formColumns: [
          {
            title: '区域',
            type: 'select',
            property: 'areaIds',
            multiple: true,
            show: true,
            options: [],
          },
          {
            title: '位置',
            type: 'select',
            property: 'placeIds',
            multiple: true,
            show: true,
            options: [],
          },
          {
            title: '费用名称',
            type: 'select',
            property: 'takeReturnProjectIds',
            multiple: true,
            show: true,
            options: [],
          },
          {
            title: '合同状态',
            type: 'select',
            property: 'contractStatus',
            show: true,
            options: [
              {
                label: '全部',
                value: '',
              },
              {
                label: '执行中',
                value: 1,
              },
              {
                label: '已退租',
                value: 2,
              },
            ],
          },
          {
            title: '费用起始时间',
            type: 'datePicker',
            property: 'feeBeginDate',
            show: true,
          },
          {
            title: '费用截止时间',
            type: 'datePicker',
            property: 'feeEndDate',
            show: true,
          },
        ],
        tableData: [],
        searchForm: {
          areaIds: null,
          placeIds: null,
          takeReturnProjectIds: null,
          contractStatus: null,
          feeBeginDate: null,
          feeEndDate: null,
          current: 1,
          size: 10,
        },
        count: null,
        exportData: {},
        labelPosition: 'right',
        labelWidth: '100px',
        form: {},
        total: {},
      };
    },
    watch: {},
    created() {
      this.getData();
      this.getAreaLabel();
      this.gettakereturnprojectLabel();
    },
    components: { ETable, EDialog, EButton, EForm },
    beforeMount() {},
    methods: {
      // 修改select
      changeSelect(prop) {
        if (prop == 'areaIds') {
          this.getPlaceLabel();
        }
      },
      exportDataFn(){
        let {
          areaIds,
          placeIds,
          takeReturnProjectIds,
          contractStatus,
          feeEndDate,
          feeBeginDate,
          current,
          size,
        }=JSON.parse(JSON.stringify(this.searchForm))
        return {
          areaIds:areaIds?areaIds.toString():'',
          placeIds:placeIds?placeIds.toString():'',
          takeReturnProjectIds:takeReturnProjectIds?takeReturnProjectIds.toString():'',
          contractStatus,
          feeEndDate,
          feeBeginDate,
          current,
          size,
        }
      },
      getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          sums.push(this.total[column.property]);
        });

        return sums;
      },
      async getData() {

        let res = await Http.getFeeCollectList(this.exportDataFn());
        if (res.code == 200) {
          this.tableData = res.data.feeList;
          this.total = res.data.sumData;
        }
      },
      //获取区域下拉
      async getAreaLabel() {
        let res = await Http.getAreaLabel({
          status: null,
        });
        if (res.code == 200) {
          this.formColumns[0].options = res.data;
        }
      },
      //获取位置下拉
      async getPlaceLabel() {
        let res = await Http.getPlaceLabel({
          areaIds: this.searchForm.areaIds?this.searchForm.areaIds.toString():'',
        });
        if (res.code == 200) {
          this.formColumns[1].options = res.data;
        }
      },
      //获取费用名称下拉
      async gettakereturnprojectLabel() {
        let res = await Http.gettakereturnprojectLabel({
          areaId: this.searchForm.areaId,
          type:"1"
      });
        if (res.code == 200) {
          this.formColumns[2].options = res.data;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
.ws {
  width: 100%;
}
</style>
